<template>
    <div class="d-flex">
        <div class="doughnut-container">
            <Doughnut :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
                :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles"
                :width="width" :height="height" />
            <div class="total-container-doughnut">
                <p class="total-price-doughnut">{{ centerText }}</p>
            </div>
        </div>
        <div class="bar-main">
            <ul class="bar-container px-0">
                <li class="horizontal-bar-container" v-for="(count, index) in counts" :key="index">
                    <div class="horizontal-bar">
                        <div class="horizontal-bar-fill" :ref="`bar-${index}`"
                            :style="{ backgroundColor: barColors[index] }">
                        </div>
                    </div>
                    <div class="info-container">
                        <div class="bars-container" style="gap:7px">
                            <div :class="squareClasses[index]"></div>
                            <p class="bar-name">{{ labels[index] }}</p>
                        </div>
                        <p class="bar-count">{{ count }}</p>
                    </div>
                </li>

            </ul>
        </div>
    </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

// Регистрация плагинов
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, ChartDataLabels)

export default {
    name: 'DoughnutChart',
    components: {
        Doughnut,
    },
    props: {
        chartId: {
            type: String,
            default: 'doughnut-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 151
        },
        height: {
            type: Number,
            default: 151
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => ({})
        },
        plugins: {
            type: Object,
            default: () => ({})
        },
        dashboardData: {
            type: Object,  // Мы ожидаем объект
            default: () => ({})
        },
    },
    data() {
        return {
            labels: ['вкус еды', 'качество упаковки', 'работа курьера'],
            counts: [
                this.dashboardData.deliveryFoodReviews,
                this.dashboardData.deliveryPackageReviews,
                this.dashboardData.deliveryCourierReviews
            ],

            barColors: ['#13414E', '#006D5A', '#14BF91'], // Задаём цвета для каждого бара

            squareClasses: ['name-square-inhouse', 'name-square-delivery', 'name-square-selfpick'],
            centerText: "",
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                cutout: '40%', // Уменьшите значение для уменьшения белого пространства
                plugins: {
                    legend: {
                        display: false // Отключаем легенду
                    },
                    tooltip: {
                        enabled: true,
                        bodyFont: {
                            family: 'Inter', // Шрифт тултипа
                            size: 12,        // Увеличиваем размер шрифта тултипа
                            // weight: 'bold'
                        },

                        padding: 5,
                        // displayColors: false, // Отключить цвета рядом с текстом для экономии пространства
                        callbacks: {
                            label: function (context) {
                                // Добавим кастомный перенос текста, если строка слишком длинная
                                let label = context.label || '';
                                if (label.length > 20) { // допустимая длина строки
                                    label = label.slice(0, 20) + '...'; // обрезаем строку, если она длиннее
                                }
                                return label;
                            }
                        },
                        caretSize: 6,
                        boxWidth: 0, // убираем квадрат около текста
                        usePointStyle: true,
                    },
                    datalabels: {
                        color: '#fff',
                        font: {
                            family: "Inter",
                            weight: '700',
                            size: 14
                        }
                    },
                }
            }
        };
    },
    computed: {
        totalCount() {
            return this.counts.reduce((sum, count) => sum + count, 0);
        },
        chartData() {
            // Создание массива данных для диаграммы
            const labels = ['вкус еды', 'качество упаковки', 'работа курьера'];
            const data = [
                this.dashboardData.deliveryFoodReviews,
                this.dashboardData.deliveryPackageReviews,
                this.dashboardData.deliveryCourierReviews
            ];


            const backgroundColors = ['#13414E', '#006D5A', '#14BF91'];

            // Фильтрация данных, меток и цветов, чтобы исключить нулевые значения
            const filteredData = data.map((value, index) => value > 0 ? value : 0);
            const filterdata1 = filteredData.filter(value => value !== 0);

            const filteredLabels = labels.filter((label, index) => data[index] > 0);
            const filteredBackgroundColors = backgroundColors.filter((color, index) => data[index] > 0); // Фильтрация цветов

            console.log(filterdata1);
            console.log(filteredData);
            console.log(filteredLabels);
            console.log(filteredBackgroundColors);

            return {
                labels: filteredLabels,
                datasets: [{
                    data: filterdata1, // Используем только отфильтрованные значения
                    backgroundColor: filteredBackgroundColors, // Применяем отфильтрованные цвета
                    borderWidth: 0
                }]
            };
        }
    },
    methods: {
        getPercentage(count) {
            return this.totalCount ? (count / this.totalCount) * 100 : 0;
        },
        animateBars() {
            this.counts.forEach((count, index) => {
                const barElement = this.$refs[`bar-${index}`][0];
                const width = this.getPercentage(count);

                // Устанавливаем анимацию с небольшой задержкой
                setTimeout(() => {
                    barElement.style.transition = 'width 1s ease';
                    barElement.style.width = `${width}%`;
                }, 100);
            });
        }

    },
    mounted() {
        this.centerText = `${this.dashboardData.deliveryCourierReviews + this.dashboardData.deliveryPackageReviews + this.dashboardData.deliveryFoodReviews}`;

        this.animateBars();
    },
}
</script>


<style scoped>
.horizontal-bar-fill {
    height: 100%;
    width: 0;
    /* Начальное значение для анимации */
    transition: width 1s ease;
    border-radius: 2px;
}

.bar-main {
    width: 100%;
}

.bar-count {
    font-family: Inter;
    font-weight: 400;
    font-size: 0.6770vw;
    color: #606c80;
    margin-bottom: 0px;
}

.bars-container {
    display: flex;
    align-items: center;

}

.bar-name {
    font-family: Inter;
    font-weight: 400;
    font-size: 0.78125vw;
    color: #323c4d;
    margin-bottom: 0px;
}

.total-price-doughnut {
    font-family: Inter;
    font-weight: 700;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 0px;
}

.total-container-doughnut {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0px;
    transform: translate(-50%, -50%);
    text-align: center;
}

.bars-main-container {
    margin-left: 67px;
    width: 100%;
    max-width: 327px;
}

.percent-status-gray {
    margin-top: 5px;
}

.percent-status-red {
    margin-top: 5px;
}

.percent-text-gray {

    /* color: #c1c1c1;
    font-family: Inter;
    font-weight: 400;
    font-size: 13px; */
    padding-top: 3px;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.5729vw;
    color: #323c4d;
}

.percent-text-red {
    color: #e41313;
    font-family: Inter;
    font-weight: 400;
    font-size: 11px;
}

.percent-text-green {
    color: #00b800;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.5729vw;
}

.neutral-arrows-special {
    margin-top: -8px
}

.neutral-arrows-special-2 {
    margin-top: -8px
}

.neutral-arrows {
    color: #98a2b2;
    /* padding-bottom: 2px;
    padding-top: 2px; */
    height: 13px;
}

.percent-status-green {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-left: 20px; */
    margin-top: 5px;
    line-height: 1;
}

.percent-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-left: 20px; */
    /* line-height: 1; */
}

.container {
    display: flex;
}

.doughnut-container {
    position: relative;
    z-index: 1;
}

.cost-container-red {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0px;
    transform: translate(-50%, -5%);
    text-align: center;

}

.cost-container-green {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0px;
    transform: translate(-50%, -5%);
    text-align: center;
}

.cost-container-gray {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0px;
    transform: translate(-50%, -5%);
    text-align: center;

}

.percent-in-doughnut {
    line-height: 0px;
    font-size: 18px;
    color: red;

}

.cost-in-doughnut {

    font-family: Inter;
    font-size: 13px;
    font-weight: 700;
    line-height: 15.73px;
    text-align: left;
    margin-bottom: 0px;
    z-index: 0;
    /* word-wrap: break-word; */
    /* Разрешить перенос слов */
    /* white-space: normal; */
    /* Позволяет тексту переноситься */
    /* max-width: 100%; */
    /* Ограничение ширины текста */
}

.arrow-color-bot {
    color: red;
    /* width: 16px;
    height: 13px; */
    /* padding-top: 2px; */
    margin-top: -7px;
}

.arrow-color-top {
    color: green;
    /* width: 16px;
    height: 13px; */
    /* padding-top: 2px; */
}

/* Horizontal price  */

.bar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
    height: 100%;
    gap: 10px;
}

.info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.horizontal-bar {
    background: #F0F0F0;
    max-width: 250px;
    height: 10px;
}

.horiontal-bar-fill {
    height: 10px;
    animation: horizontal-fill 1s;
    transition: all 1s ease-in-out;
}

@keyframes horizontal-fill {
    from {
        width: 0%;
    }

    to {
        width: inherit;
    }
}

.horizontal-bar-container {
    list-style-type: none;
}

.name-container {
    display: flex;
    align-items: center;
}

.name-container p {
    line-height: 0px;
    margin-top: 15px;
    margin-left: 10px;
}

.name-square-delivery {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color: #006D5A;
}

.name-square-inhouse {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color: #13414E;
}

.name-square-selfpick {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color: #14BF91;
}
</style>